import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  useGetTournamentTablesQuery,
  useMatchResultMutation,
  useMatchTablesMutation,
} from "../../../redux/features/api/apiSlice";
import { useSelector } from "react-redux";
import { ChevronDownIcon, SquaresPlusIcon } from "@heroicons/react/16/solid";
import { useParams } from "react-router-dom";

const MatchSetting = ({ match, admin, setEditMatch }) => {
  let { tournamentId } = useParams();
  const [matchTables] = useMatchTablesMutation();
  const [matchResult] = useMatchResultMutation();
  const { isFetching } = useGetTournamentTablesQuery(tournamentId, {
    skip: !tournamentId,
  });
  const { tables } = useSelector((state) => state.tables) || [];
  const [editTable, setEditTable] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTable, setSelectedTable] = useState(match?.matchTables);
  const [finalResult, setFinalResult] = useState(
    match.result || { A: 0, B: 0 }
  );
  const gamesPerMatch = match?.draw?.defaultMatchFormat?.gamesPerMatch || 5;
  const pointsPerGame = match?.draw?.defaultMatchFormat?.pointsPerGame || 11;
  const deuce = match?.draw?.defaultMatchFormat?.deuce || true;
  // const playAllGames = match?.draw?.defaultMatchFormat?.playAllGames || false;
  const [rounds, setRounds] = useState(
    match?.rounds || Array(gamesPerMatch).fill({ A: "", B: "" })
  );
  const [date, setDate] = useState(match?.startDate || match?.draw?.startDate || '');
  const [time, setTime] = useState(match?.startTime || match?.draw?.startTime || "");

  const handleTables = (table) => {
    setSelectedTable((prevSelected) => {
      if (prevSelected.some((t) => t.index === table.index)) {
        return prevSelected.filter((t) => t.index !== table.index);
      } else {
        return [...prevSelected, table];
      }
    });
  };

  const handleMatchTables = async (id) => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await matchTables({ id, data: selectedTable });
      if (res?.data?.error === false) {
        toast.success(res.data.data);
      }
      if (res?.error?.data?.error !== undefined) {
        toast.success(res.error.data.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMatchResult = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const { id, drawFormat, roundRobinSettings } = match?.draw;
      const newDraw = { id, drawFormat, roundRobinSettings };
      const res = await matchResult({
        id: match.id,
        data: { rounds, finalResult, date, time, newDraw, admin },
      });
      if (res?.data?.error === false) {
        toast.success(res.data.data);
      }
      if (res?.error?.data?.error !== undefined) {
        toast.success(res.error.data.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRoundChange = (index, player, value) => {
    const updatedRounds = rounds.slice();
    updatedRounds[index] = { ...updatedRounds[index], [player]: value };
    setRounds(updatedRounds);

    calculateResult(updatedRounds);
  };

  const calculateResult = (rounds) => {
    let playerAWins = 0;
    let playerBWins = 0;

    rounds.forEach((round, index) => {
      if (index < gamesPerMatch) {
        const scoreA = parseInt(round.A, 10);
        const scoreB = parseInt(round.B, 10);

        if (!isNaN(scoreA) && !isNaN(scoreB)) {
          if (deuce) {
            if (scoreA >= pointsPerGame && scoreA >= scoreB + 2) {
              playerAWins++;
            } else if (scoreB >= pointsPerGame && scoreB >= scoreA + 2) {
              playerBWins++;
            }
          } else {
            if (scoreA > scoreB) {
              playerAWins++;
            } else if (scoreB > scoreA) {
              playerBWins++;
            }
          }
        }
      }
    });

    setFinalResult({ A: playerAWins, B: playerBWins });
  };

  useEffect(() => {
    setRounds(match?.rounds || Array(gamesPerMatch).fill({ A: "", B: "" }));
  }, [match, gamesPerMatch]);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (e.target.classList.contains("modal-overlay")) {
        setEditMatch(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [setEditMatch]);

  return (
    <>
      <div
        className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50 modal-overlay"
        onClick={() => setEditMatch(false)}
      >
        <div
          className="bg-white rounded-md  shadow-lg relative"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex justify-end p-2 bg-blue-800 rounded-t-md">
            <button
              className="px-2 rounded border-2 bg-white border-gray-600 font-medium mb-2"
              onClick={() => setEditMatch()} // Close when clicking the "x"
            >
              x
            </button>
          </div>

          <div className="px-10 py-3">
            {match?.draw?.scheduleMatches && (
              <div className="flex w-[50%] gap-5 mt-5">
                <div className="flex flex-col items-start justify-start w-full col-span-3">
                  <label
                    htmlFor="startDate"
                    className="block col-span-4 text-xs font-medium leading-6 text-gray-600"
                  >
                    Start Date
                  </label>
                  <input
                    disabled={!match?.draw?.scheduleMatches}
                    className="w-full col-span-8 p-1 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 "
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    type="date"
                    min={match?.draw?.startDate}
                    max={match?.tournament?.endDate}
                  />
                </div>

                <div className="flex flex-col items-start justify-start w-full col-span-3">
                  <label
                    htmlFor="startTime"
                    className="block col-span-4 text-xs font-medium leading-6 text-gray-600"
                  >
                    Start Time
                  </label>
                  <input
                    disabled={!match?.draw?.scheduleMatches}
                    className="w-full col-span-8 p-1 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 "
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                    type="time"
                  />
                </div>
              </div>
            )}

            <div className={`w-full items-center`}>
              <div
                className={`w-full border shadow border-gray-300 rounded mt-5 mb-5`}
              >
                <div className="flex">
                  <div className="w-full grid grid-cols-2">
                    {(() => {
                      const sortedPlayers = match?.matchPlayers
                        ? match?.matchPlayers
                            ?.slice()
                            .sort(
                              (a, b) =>
                                a?.PlayerMatch?.number - b?.PlayerMatch?.number
                            )
                        : [];

                      const filledPlayers = [
                        sortedPlayers[0] || {
                          PlayerMatch: { number: 0 },
                          firstName: "TBD",
                          lastName: "",
                          rating: "",
                          homeAddress: "",
                        },
                        sortedPlayers[1] || {
                          PlayerMatch: { number: 1 },
                          firstName: "TBD",
                          lastName: "",
                          rating: "",
                          homeAddress: "",
                        },
                      ];

                      return filledPlayers.map((player, i) => (
                        <div
                          key={i}
                          className="px-1 py-3 flex gap-3 items-center border-l"
                        >
                          <div
                            className={`w-6 h-6 flex justify-center items-center text-white font-medium ${
                              player?.PlayerMatch?.number === 0
                                ? "bg-blue-600"
                                : "bg-rose-600"
                            }`}
                          >
                            {player?.PlayerMatch?.number === 0 ? "A" : "B"}
                          </div>
                          <div>
                            <div className="text-[10px] flex gap-3">
                              <span className="font-bold">
                                {player?.rating || ""}
                              </span>{" "}
                              {player?.homeAddress || ""}
                            </div>
                            <div className="font-medium text-sm">
                              {player?.firstName
                                ? `${player.firstName}${
                                    player.lastName
                                      ? ", " + player.lastName
                                      : ""
                                  }`
                                : "TBD"}
                            </div>
                          </div>
                        </div>
                      ));
                    })()}
                  </div>
                </div>
                <div className="grid w-full grid-cols-7 text-lg">
                  <div className="flex flex-col items-center justify-start col-span-1 h-full">
                    <div className="w-full py-2 text-center text-white bg-blue-600 border border-blue-600 h-full">
                      A
                    </div>
                    <div className="w-full py-2 text-center text-white border border-rose-600 bg-rose-600 h-full">
                      B
                    </div>
                  </div>
                  {Array(5)
                    .fill(null)
                    .map((_, index) => {
                      const isDisabled = index >= gamesPerMatch; // Disable inputs for rounds beyond the gamesPerMatch

                      return (
                        <div
                          className="flex flex-col items-center justify-start col-span-1 h-full"
                          key={index}
                        >
                          <input
                            type="number"
                            className="w-20 py-2 text-center border h-full"
                            value={
                              rounds[index]?.A === 0
                                ? 0
                                : rounds[index]?.A || ""
                            }
                            min={0}
                            max={50}
                            disabled={isDisabled}
                            onChange={(e) => {
                              let value = e.target.value;
                              if (value === "") {
                                value = ""; // Allow clearing the input
                              } else if (parseInt(value) < 0) {
                                value = 0;
                              } else if (parseInt(value) > 50) {
                                value = 50;
                              } else {
                                value = parseInt(value, 10);
                              }

                              handleRoundChange(index, "A", value);
                            }}
                          />
                          <input
                            type="number"
                            className="w-20 py-2 text-center border h-full"
                            value={
                              rounds[index]?.B === 0
                                ? 0
                                : rounds[index]?.B || ""
                            }
                            min={0}
                            max={50}
                            disabled={isDisabled}
                            onChange={(e) => {
                              let value = e.target.value;
                              if (value === "") {
                                value = ""; // Allow clearing the input
                              } else if (parseInt(value) < 0) {
                                value = 0;
                              } else if (parseInt(value) > 50) {
                                value = 50;
                              } else {
                                value = parseInt(value, 10);
                              }

                              handleRoundChange(index, "B", value);
                            }}
                          />
                        </div>
                      );
                    })}
                  <div className="flex flex-col items-center justify-start col-span-1 h-full text-white font-bold">
                    <div className="w-full py-2 text-center border h-full bg-blue-600">
                      {finalResult.A}
                    </div>
                    <div className="w-full py-2 text-center border h-full bg-rose-600">
                      {finalResult.B}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex ">
              <div className="flex items-center gap-3">
                {admin &&
                  (!editTable ? (
                    <button
                      onClick={() => {
                        setEditTable(true);
                        setShowTable(true);
                      }}
                      className="flex items-center gap-1 border-2 border-gray-600 rounded px-2"
                    >
                      <div className=" py-2 rounded bg-white text-black text-sm font-medium">
                        {match?.matchTables?.length < 1 ? (
                          "No Table"
                        ) : (
                          <>
                            Table{" "}
                            {match?.matchTables
                              ?.slice() // Create a shallow copy of the array
                              .sort((a, b) => a.index - b.index) // Sort the copied array
                              .map((table, i) => (
                                <span key={i}>
                                  {table.index}
                                  {match?.matchTables?.length !== i + 1 && ", "}
                                </span>
                              ))}
                          </>
                        )}
                      </div>

                      <ChevronDownIcon height={20} width={20} />
                    </button>
                  ) : (
                    <div className="flex items-center gap-2">
                      <button
                        className="text-lg font-medium bg-white rounded h-6 w-6 text-black"
                        onClick={() => {
                          setEditTable(false);
                          setShowTable(false);
                        }}
                      >
                        X
                      </button>
                      <button
                        onClick={() => setShowTable(!showTable)}
                        className="flex gap-1 items-center py-2 bg-white text-black rounded px-2 text-sm"
                      >
                        {selectedTable?.length < 1
                          ? "No Table"
                          : selectedTable
                              .slice() // Create a shallow copy of the array
                              .sort((a, b) => a.index - b.index) // Sort the copied array
                              .map((table, i) => (
                                <span key={i}>
                                  {table.index}{" "}
                                  {selectedTable.length !== i + 1 && ","}
                                </span>
                              ))}
                      </button>
                      {showTable && tables.length > 0 && (
                        <div className="absolute z-60 mt-[290px] space-y-2 border shadow bg-white max-h-[250px] overflow-y-scroll rounded-lg p-3">
                          {selectedTable?.length > 0 && (
                            <button
                              onClick={() => setSelectedTable([])}
                              className="text-black text-sm text-center w-full hover:bg-gray-100 py-1"
                            >
                              Reset
                            </button>
                          )}
                          {tables?.map((table, i) => {
                            const isSelected = selectedTable.some(
                              (t) => t.id === table.id
                            );
                            return (
                              <div key={i} className="text-black text-sm">
                                <button
                                  onClick={() => handleTables(table)}
                                  className={`flex gap-2 px-1 py-1 ${
                                    isSelected
                                      ? "bg-gray-200"
                                      : "hover:bg-gray-200"
                                  }`}
                                >
                                  Table {table.index}{" "}
                                  <span className="bg-blue-300 px-2">
                                    {table?.tableNumber}
                                  </span>
                                </button>
                              </div>
                            );
                          })}
                        </div>
                      )}
                      <button
                        onClick={() => handleMatchTables(match.id)}
                        className="bg-blue-500 px-3 py-2 text-xs font-medium rounded shadow text-white "
                      >
                        Apply
                      </button>
                    </div>
                  ))}
              </div>
            </div>
            <div className="flex justify-end mt-10">
              <button
                onClick={handleMatchResult}
                className="bg-green-500 px-6 py-3 border shadow rounded text-white font-medium text-sm"
              >
                {loading ? 'loading..' : admin ? 'VERIFY': 'SUBMIT'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MatchSetting;
