import { XCircleIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import FormFieldError from "../../../components/commons/formFeildError.";
import { tournamentInitialState } from "../../../redux/features/tournamentSlice";
import {
  useAddNewTournamentMutation,
  useUpdateTournamentMutation,
} from "../../../redux/features/api/apiSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const AddTournamentForm = ({ handleCloseForm, tournament }) => {
  const [addNewTournament] = useAddNewTournamentMutation();
  const [updateTournament] = useUpdateTournamentMutation();
  const { currentUser } = useSelector((state) => state.user);
  const [showDetails, setShowDetails] = useState(true);
  const [loading, setLoading] = useState(false);
  const [publish, setPublish] = useState(false);
  const [draft, setDraft] = useState(false);
  const [showDates, setShowDates] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [showLocation, setShowLocation] = useState(false);

  const pickFormFields = (data, allowedFields) => {
    return Object.keys(data)
      .filter((key) => allowedFields.includes(key))
      .reduce((obj, key) => {
        obj[key] = data[key];
        return obj;
      }, {});
  };

  const allowedFields = [
    "tournamentName",
    "membershipSystem",
    "tournamentFee",
    "tournamentCurrencyType",
    "tournamentRatingFormula",
    "timezone",
    "note",
    "startDate",
    "endDate",
    "ratingCutoffDate",
    "email",
    "phone",
    "country",
    "city",
    "state",
    "address1",
    "zip",
    "ratingCutOffDate",
    "email",
    "customSettings",
    "tournamentManagers",
  ];

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: tournament || tournamentInitialState,
  });

  const {
    fields: tournamentManagersFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "tournamentManagers",
  });

  const onSubmit = async (data) => {
    if(loading) return
    setLoading(true)
    try {
      if (tournament?.id) {
        const filteredData = pickFormFields(data, allowedFields);
        const res = await updateTournament({ id: tournament.id, data: filteredData });
        if(!res.data.error){
          toast.success('Tournament Updated')
        }
      } else {
        data.userId = currentUser.id;
        const res = await addNewTournament(data);
        if(!res.data.error){
          toast.success('Tournament Added')
        }
      }
    } catch (error) {
      console.log(error)
      toast.error('Something went wrong')
    }finally{
      setLoading(false)
      handleCloseForm();
    }
  };

  const handlePublish = handleSubmit(async (data) => {
    if(publish) return
    setPublish(true)
    try {
      if (tournament?.id) {
        const filteredData = pickFormFields(data, allowedFields);
        filteredData.status = "published";
        const res = await updateTournament({ id: tournament.id, data: filteredData });
        if(!res.data.error){
          toast.success('Tournament Published')
        }
      } else {
        data.userId = currentUser.id;
        data.status = "published";
        const res = await addNewTournament(data);
        if(!res.data.error){
          toast.success('Tournament Published')
        }
      }
    } catch (error) {
      toast.error('Something went wrong')
    }finally{
      setPublish(false)
      handleCloseForm();
    }
  });

  const handleDraft = handleSubmit(async (data) => {
    if(draft) return
    setDraft(true)
    try {
      if (tournament?.id) {
        const filteredData = pickFormFields(data, allowedFields);
        filteredData.status = "draft";
        const res = await updateTournament({ id: tournament.id, data: filteredData });
        if(!res.data.error){
          toast.success('Tournament Drafted')
        }
      } else {
        data.userId = currentUser.id;
        data.status = "draft";
        const res = await addNewTournament(data);
        if(!res.data.error){
          toast.success('Tournament Drafted')
        }
      }
    } catch (error) {
      toast.error('Something went wrong')
    }finally{
      setDraft(false)
      handleCloseForm();
    }
  });

  const startDate = watch("startDate");
  const hasContactErrors = !!errors.email;
  const hasDetailErrors = !!errors.tournamnetName;
  const hasDateErrors =
    !!errors.startDate || !!errors.endDate || !!errors.ratingCutoffDate;

  return (
    
    <form className="mb-40" onSubmit={handleSubmit(onSubmit)}>
      <div className="p-3 sm:p-8 space-y-4 ">
        <div className="sm:flex flex-row items-center justify-between">
          <div>
            <h1 className="text-lg font-semibold text-blue-600 ">
              {tournament?.id ? "Update Tournament" : "Add New Tournament"}
            </h1>
            <p className="text-sm text-gray-600 ">
              Please fill in the form below for tournament
            </p>
          </div>
          <div className="flex flex-row items-center justify-between gap-3 mt-5 sm:mt-0">
            <button
              type="button"
              onClick={handleCloseForm}
              className="inline-flex items-center border-2 border-gray-400 px-3 py-2 text-sm font-semibold text-white bg-gray-400 rounded-md shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="inline-flex whitespace-nowrap items-center px-4 py-2 text-sm font-semibold text-indigo-600 border-2 border-indigo-600 rounded-md shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {loading ? 'loading..' : 'Save'}
            </button>
            {tournament?.status === "published" ? (
              <button
                type="button"
                onClick={handleDraft}
                className="inline-flex items-center px-3 py-2 text-sm font-semibold text-white border-2 border-gray-400 bg-gray-600 rounded-md shadow-sm hover:bg-gray-500"
              >
                {draft ? 'loading..' : 'Draft'}
              </button>
            ) : (
              <button
                type="button"
                onClick={handlePublish}
                className="inline-flex items-center px-3 py-2 text-sm font-semibold text-white border-2 border-blue-700 bg-blue-700 rounded-md shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {publish ? 'loading..':'Publish'}
              </button>
            )}
          </div>
        </div>
        <div className="grid items-center max-w-4xl mx-auto">
          <button
            onClick={() => setShowDetails(!showDetails)}
            type="button"
            className={`mt-10 relative w-full border-b border-white flex justify-between items-center px-10 py-4  text-gray-700 rounded-t-2xl hover:bg-gray-300 ${
              showDetails ? "bg-gray-300" : "bg-gray-200"
            }`}
          >
            <span>
              Tournament Details{" "}
              {hasDetailErrors && (
                <span className="text-red-500 text-2xl font-bold">*</span>
              )}
            </span>
            <svg
              data-accordion-icon
              className={`w-3 h-3 ${showDetails ? "" : "rotate-180"} shrink-0`}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path stroke="currentColor" d="M9 5 5 1 1 5" />
            </svg>
          </button>
          <div
            className={`grid items-center border border-gray-300 justify-between grid-cols-12 gap-4 transition-all duration-500 ease-in-out ${
              showDetails
                ? "max-h-[1000px] opacity-100 p-5"
                : "max-h-0 opacity-0 overflow-hidden"
            }`}
          >
            <div className="flex flex-col items-start justify-start w-full col-span-12 sm:col-span-5">
              <label
                htmlFor="tournamentName"
                className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
              >
                Tournament Name
              </label>
              <input
                className="w-full col-span-8  border-2 border-gray-200 bg-white py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-md sm:leading-6 "
                id="tournamentName"
                {...register("tournamentName", {
                  required: "Tournament name is required",
                })}
              />
              <FormFieldError errors={errors} field={"tournamentName"} />
            </div>

            <div className="flex flex-col items-start justify-start w-full col-span-6 sm:col-span-4">
              <label
                htmlFor="membershipSystem"
                className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
              >
                Membership System
              </label>
              <select
                id="membershipSystem"
                className="w-full col-span-8  border-2 border-gray-200 bg-white py-2 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-md sm:leading-6 "
                {...register("membershipSystem")}
              >
                <option value="none">None</option>
                {/* <option value="rating-central">Rating Central</option> */}
                <option value="usa-tt">USA Table Tennis</option>
              </select>

              <FormFieldError errors={errors} field={"membershipSystem"} />
            </div>
            <div className="flex flex-col items-start justify-start w-full col-span-6 sm:col-span-3">
              <label
                htmlFor="tournamentCurrencyType"
                className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
              >
                Tournament Currency
              </label>
              <input
                className="w-full col-span-8  border-2 border-gray-200 bg-white py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-md sm:leading-6 "
                id="tournamentCurrencyType"
                {...register("tournamentCurrencyType")}
              />
              <FormFieldError
                errors={errors}
                field={"tournamentCurrencyType"}
              />
            </div>

            <div className="flex flex-col items-start justify-start w-full col-span-6 sm:col-span-4">
              <label
                htmlFor="tournamentFee"
                className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
              >
                Tournament Fee
              </label>
              <input
                className="w-full col-span-8  border-2 border-gray-200 bg-white py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-md sm:leading-6 "
                id="tournamentFee"
                type="number"
                min={0}
                {...register("tournamentFee")}
              />
              <FormFieldError errors={errors} field={"tournamentFee"} />
            </div>

            <div className="flex flex-col items-start justify-start w-full col-span-6 sm:col-span-4">
              <label
                htmlFor="teamRatingFormula"
                className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
              >
                Team Rating Formula
              </label>
              <select
                className="w-full col-span-8  border-2 border-gray-300 bg-white py-2 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-md sm:leading-6 "
                id="teamRatingFormula"
                type="text"
                {...register("teamRatingFormula")}
              >
                <option value="balanced"> Balanced</option>
                <option value="average"> Average</option>
                <option value="sum"> Sum</option>
              </select>
              <FormFieldError errors={errors} field={"teamRatingFormula"} />
            </div>

            <div className="flex flex-col items-start justify-start w-full col-span-8 sm:col-span-4">
              <label
                htmlFor="timezone"
                className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
              >
                Timezone
              </label>
              <input
                className="w-full col-span-8  border-2 border-gray-200 bg-white py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-md sm:leading-6 "
                id="timezone"
                {...register("timezone")}
              />
              <FormFieldError errors={errors} field={"timezone"} />
            </div>
            <div className="flex flex-col items-start justify-start w-full col-span-12">
              <label
                htmlFor="note"
                className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
              >
                Tournament Note
              </label>
              <textarea
                className="w-full col-span-8  border-2 border-gray-200 bg-white py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-md sm:leading-6 "
                id="note"
                rows={3}
                placeholder="max 300 letters"
                multiple
                maxLength={300}
                {...register("note")}
              />
            </div>
            <div className="col-span-12 flex justify-end">
              <button
                type="button"
                onClick={() => {
                  setShowDetails(false);
                  setShowDates(true);
                }}
                className="px-5 self-end text-white font-medium py-2 text-xs sm:text-sm bg-blue-600 rounded"
              >
                Next
              </button>
            </div>
          </div>
          <button
            onClick={() => setShowDates(!showDates)}
            type="button"
            className={`relative w-full border-b border-white flex justify-between items-center px-10 py-4  text-gray-700 hover:bg-gray-300 ${
              showDates ? "bg-gray-300" : "bg-gray-200"
            }`}
          >
            <span>
              Tournament Dates{" "}
              {hasDateErrors && (
                <span className="text-red-500 text-2xl font-bold">*</span>
              )}
            </span>
            <svg
              data-accordion-icon
              className={`w-3 h-3 ${showDates ? "" : "rotate-180"} shrink-0`}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path stroke="currentColor" d="M9 5 5 1 1 5" />
            </svg>
          </button>
          <div
            className={`grid items-center border border-gray-300 justify-between grid-cols-12 gap-4 transition-all duration-500 ease-in-out ${
              showDates
                ? "max-h-[1000px] opacity-100 p-5"
                : "max-h-0 opacity-0 overflow-hidden"
            }`}
          >
            <div className="flex flex-col items-start justify-start w-full col-span-6 sm:col-span-4">
              <label
                htmlFor="startDate"
                className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
              >
                Start Date
              </label>
              <input
                className="w-full col-span-8 border-2 border-gray-200 bg-white py-1.5 p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm rounded-md sm:leading-6"
                min={new Date().toISOString().split("T")[0]}
                id="startDate"
                type="date"
                {...register("startDate", {
                  required: "Start date is required",
                })}
              />
              <FormFieldError errors={errors} field={"startDate"} />
            </div>

            <div className="flex flex-col items-start justify-start w-full col-span-6 sm:col-span-4">
              <label
                htmlFor="endDate"
                className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
              >
                End Date
              </label>
              <input
                className="w-full col-span-8 border-2 border-gray-200 bg-white py-1.5 p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm rounded-md sm:leading-6"
                min={startDate || new Date().toISOString().split("T")[0]} // Set min to startDate or today's date
                id="endDate"
                type="date"
                {...register("endDate", { required: "End date is required" })}
              />
              <FormFieldError errors={errors} field={"endDate"} />
            </div>

            <div className="flex flex-col items-start justify-start w-full col-span-7 sm:col-span-4">
              <label
                htmlFor="ratingCutoffDate"
                className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
              >
                Rating Cutoff Date
              </label>
              <input
                className="w-full col-span-8  border-2 border-gray-200 bg-white py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-md sm:leading-6 "
                id="ratingCutoffDate"
                type="date"
                max={new Date().toISOString().split("T")[0]}
                {...register("ratingCutoffDate", {
                  required: "Rating cutoff date is required",
                })}
              />
              <FormFieldError errors={errors} field={"ratingCutoffDate"} />
            </div>
            <div className="col-span-12 flex justify-end">
              <button
                type="button"
                onClick={() => {
                  setShowDates(false);
                  setShowContact(true);
                }}
                className="px-5 self-end text-white font-medium py-2 text-xs sm:text-sm bg-blue-600 rounded"
              >
                Next
              </button>
            </div>
          </div>
          <button
            onClick={() => setShowContact(!showContact)}
            type="button"
            className={`relative w-full border-b border-white flex justify-between items-center px-10 py-4  text-gray-700 hover:bg-gray-300 ${
              showContact ? "bg-gray-300" : "bg-gray-200"
            }`}
          >
            <span>
              Tournament Contact{" "}
              {hasContactErrors && (
                <span className="text-red-500 text-2xl font-bold">*</span>
              )}
            </span>
            <svg
              data-accordion-icon
              className={`w-3 h-3 ${showContact ? "" : "rotate-180"} shrink-0`}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path stroke="currentColor" d="M9 5 5 1 1 5" />
            </svg>
          </button>
          <div
            className={`grid items-center border border-gray-300 justify-between grid-cols-12 gap-4 transition-all duration-500 ease-in-out ${
              showContact
                ? "max-h-[1000px] opacity-100 p-5"
                : "max-h-0 opacity-0 overflow-hidden"
            }`}
          >
            <div className="flex flex-col items-start justify-start w-full col-span-6">
              <label
                htmlFor="email"
                className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
              >
                Email
              </label>
              <input
                className="w-full col-span-8  border-2 border-gray-200 bg-white py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-md sm:leading-6 "
                id="email"
                type="email"
                {...register("email", { required: "Email is required" })}
              />
              <FormFieldError errors={errors} field={"email"} />
            </div>

            <div className="flex flex-col items-start justify-start w-full col-span-6">
              <label
                htmlFor="phone"
                className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
              >
                Phone
              </label>
              <input
                className="w-full col-span-8  border-2 border-gray-200 bg-white py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-md sm:leading-6 "
                id="phone"
                minLength={3}
                maxLength={20}
                type="tel"
                {...register("phone")}
              />
              <FormFieldError errors={errors} field={"phone"} />
            </div>
            <div className="col-span-12 flex justify-end">
              <button
                type="button"
                onClick={() => {
                  setShowContact(false);
                  setShowLocation(true);
                }}
                className="px-5 self-end text-white font-medium py-2 text-xs sm:text-sm bg-blue-600 rounded"
              >
                Next
              </button>
            </div>
          </div>
          <button
            onClick={() => setShowLocation(!showLocation)}
            type="button"
            className={`relative w-full border-b border-white flex justify-between items-center px-10 py-4  text-gray-700 hover:bg-gray-300 ${
              showLocation ? "bg-gray-300" : "bg-gray-200"
            }`}
          >
            <span>Tournament Location</span>
            <svg
              data-accordion-icon
              className={`w-3 h-3 ${showLocation ? "" : "rotate-180"} shrink-0`}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path stroke="currentColor" d="M9 5 5 1 1 5" />
            </svg>
          </button>
          <div
            className={`grid items-center border border-gray-300 justify-between grid-cols-12 gap-4 transition-all duration-500 ease-in-out ${
              showLocation
                ? "max-h-[1000px] opacity-100 p-5"
                : "max-h-0 opacity-0 overflow-hidden"
            }`}
          >
            <div className="flex flex-col items-start justify-start col-span-6 sm:col-span-4">
              <label
                htmlFor="country"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Country
              </label>
              <input
                className="w-full border-2 border-gray-200 bg-white py-1.5 p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm rounded-md sm:leading-6"
                id="country"
                {...register("country")}
              />
              <FormFieldError errors={errors} field={"country"} />
            </div>
            <div className="flex flex-col items-start justify-start col-span-6 sm:col-span-4">
              <label
                htmlFor="city"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                City
              </label>
              <input
                className="w-full border-2 border-gray-200 bg-white py-1.5 p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm rounded-md sm:leading-6"
                id="city"
                {...register("city")}
              />
              <FormFieldError errors={errors} field={"city"} />
            </div>
            <div className="flex flex-col items-start justify-start col-span-6 sm:col-span-4">
              <label
                htmlFor="state"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                State
              </label>
              <input
                className="w-full border-2 border-gray-200 bg-white py-1.5 p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm rounded-md sm:leading-6"
                id="state"
                {...register("state")}
              />
              <FormFieldError errors={errors} field={"state"} />
            </div>
            <div className="flex flex-col items-start justify-start col-span-12 sm:col-span-8">
              <label
                htmlFor="address1"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Address
              </label>
              <input
                className="w-full border-2 border-gray-200 bg-white py-1.5 p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm rounded-md sm:leading-6"
                id="address1"
                {...register("address1")}
              />
              <FormFieldError errors={errors} field={"address1"} />
            </div>
            <div className="flex flex-col items-start justify-start col-span-6 sm:col-span-4">
              <label
                htmlFor="zip"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Zip
              </label>
              <input
                className="w-full border-2 border-gray-200 bg-white py-1.5 p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm rounded-md sm:leading-6"
                id="zip"
                type='number'
                {...register("zip")}
              />
              <FormFieldError errors={errors} field={"zip"} />
            </div>
          </div>
        </div>

        <div className="grid items-center justify-between grid-cols-12 gap-4 space-y-5 max-w-4xl mx-auto">
          <div className="flex flex-col items-start justify-start w-full col-span-12">
            <label
              htmlFor="tournamentManagers"
              className="block col-span-4 text-lg font-medium leading-6 text-gray-900"
            >
              Tournament Managers
            </label>
            <div className="w-full ">
              {tournamentManagersFields.map((field, index) => (
                <div
                  key={field.id}
                  className="flex items-center px-2 mb-2 space-x-2 border-2 border-gray-300"
                >
                  <input
                    className="w-full col-span28 bg-white py-1.5 p-2 text-gray-900 placeholder:text-gray-400 outline-none focus:ring-0 sm:text-sm rounded-md sm:leading-6"
                    id={`tournamentManagers[${index}].email`}
                    {...register(`tournamentManagers[${index}].email`, {
                      required: "Manager email is required",
                    })}
                    placeholder="Manager Email"
                    defaultValue={field.email || ""}
                  />
                  <button type="button" onClick={() => remove(index)}>
                    <XCircleIcon className="w-6 h-6 text-red-500" />
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={() => append({ email: "" })}
                className="mt-2 text-blue-500"
              >
                Add Manager
              </button>
            </div>
          </div>

          <FormFieldError errors={errors} field={"tournamentManagers"} />

          <div className="flex flex-col items-start justify-start col-span-12 gap-4 ">
            <h2 className="text-lg font-medium text-gray-900">
              Custom Settings
            </h2>
            <div className="flex flex-row flex-wrap items-center justify-between w-full">
              <label
                htmlFor="enablePlayerList"
                className="text-sm font-medium text-gray-900"
              >
                <input
                  className="mr-2 "
                  id="enablePlayerList"
                  type="checkbox"
                  {...register("customSettings.enablePlayerList")}
                />
                Enable Player List
              </label>
              <label
                htmlFor="enableAutoCertifyResults"
                className="block text-sm font-medium text-gray-900"
              >
                <input
                  className="mr-2 "
                  id="enableAutoCertifyResults"
                  type="checkbox"
                  {...register("customSettings.enableAutoCertifyResults")}
                />
                Auto-Certify Results
              </label>
              <label
                htmlFor="enableSelfRegistrations"
                className="block text-sm font-medium text-gray-900"
              >
                <input
                  className="mr-2 "
                  id="enableSelfRegistrations"
                  type="checkbox"
                  {...register("customSettings.enableSelfRegistrations")}
                />
                Allow Self-Registration
              </label>
              {/* <label
                htmlFor="enableSimpleVerification"
                className="block text-sm font-medium text-gray-900"
              >
                <input
                  className="mr-2 "
                  id="enableSimpleVerification"
                  type="checkbox"
                  {...register("customSettings.enableSimpleVerification")}
                />
                Use Simple Verification (PIN)
              </label> */}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddTournamentForm;
