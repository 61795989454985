import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetEventDrawQuery } from "../../../redux/features/api/apiSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import GroupCard from "../../card/Group";
import loadingImg from "../../../images/loading-image.svg";

import FinalisedSingleElimination from "../draws/FinalisedSingleElimination";

const UserDrawsTab = ({ event, tournament }) => {
  let { eventId } = useParams();
  const { isFetching, refetch } = useGetEventDrawQuery(eventId);
  const { draw } = useSelector((state) => state.draws);
  const [stage, setStage] = useState(
    draw?.roundRobinSettings?.activateSE || false
  );

  useEffect(() => {
    refetch();
  }, [eventId]);

  if (isFetching)
    return (
      <div className=" h-screen w-full flex justify-center items-center">
        <img className="-mt-32" src={loadingImg} alt="" />
      </div>
    );

  return (
    <>
      <div className="flex flex-col items-start justify-start w-full mt-8">
        {draw?.id && draw?.finalised ? (
          <div className="w-full">
            <div className="flex justify-between">
              <div className="flex items-start gap-2 mt-5 mb-10">
                <div className=" ">
                  <div className="flex items-center gap-3">
                    <div className="text-lg font-bold">{draw?.drawName}</div>
                  </div>
                  <div className="flex items-center gap-1 mt-2">
                    <div className="text-xs font-medium text-gray-600">
                      Estemated Start: {draw?.startDate?.split("T")[0]},{" "}
                    </div>
                    <div className="text-xs font-medium text-gray-600">
                      {" "}
                      {draw?.startTime}
                    </div>
                  </div>
                  <div className="flex items-center gap-1 mt-1">
                    <div className="text-xs font-medium text-gray-600">
                      Format:{" "}
                      {draw?.drawFormat === "RR"
                        ? "Round-Robin"
                        : "Single Elimination"}
                    </div>
                  </div>
                  <div className="flex items-center gap-1 mt-1">
                    <div className="text-xs font-medium text-gray-600">
                      First to {draw?.defaultMatchFormat?.pointsPerGame} (Win by{" "}
                      {draw?.defaultMatchFormat?.deuce ? "2" : "1"} ){" "}
                      <span className="text-sm font-black">.</span> Best of{" "}
                      {draw?.defaultMatchFormat?.gamesPerMatch}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {draw?.finalised && (
              <>
                {draw?.drawFormat === "RR" ? (
                  <>
                    {draw?.finalised ? (
                      <div className="w-full">
                        <div className="w-full flex gap-3 justify-center mb-10">
                          <button
                            onClick={() => setStage(false)}
                            className={`font-medium px-3 py-3 rounded text-white ${
                              !stage ? "bg-blue-800" : "bg-gray-300"
                            }`}
                          >
                            Group Stage
                          </button>
                          <button
                            onClick={() => setStage(true)}
                            className={`font-medium px-3 py-3 rounded text-white ${
                              stage ? "bg-blue-800" : "bg-gray-300"
                            }`}
                          >
                            Knockout Stage
                          </button>
                        </div>
                        {!stage ? (
                          <div className="w-full grid grid-cols-2 gap-10">
                            {draw?.groups &&
                              draw?.groups?.length &&
                              draw.groups
                                .filter((group) => group.type === "RR")
                                .slice()
                                .sort((a, b) => a.number - b.number)
                                .map((group, i) => (
                                  <GroupCard
                                    group={group}
                                    roundRobbin={draw?.roundRobinSettings}
                                    key={i}
                                    admin={false}
                                  />
                                ))}
                          </div>
                        ) : (
                          <div>
                            <FinalisedSingleElimination
                              drawId={draw?.id}
                              admin={false}
                            />
                          </div>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}{" "}
                  </>
                ) : (
                  <div>
                    <FinalisedSingleElimination drawId={draw?.id} admin={false} />
                  </div>
                )}
              </>
            )}
          </div>
        ) : (
          <div className="w-full text-center text-gray-500">
            No Available Draws
          </div>
        )}
      </div>
    </>
  );
};

export default UserDrawsTab;
