import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSignupMutation } from "../../redux/features/api/apiSlice";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/features/userSlice";
import FormFieldError from "../../components/commons/formFeildError.";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SignUp = () => {
  const [signup] = useSignupMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const onSubmit = async (data) => {
    if(loading) return
    setLoading(true)
    try {
      if (data.password === data.confirm) {
        const result = await signup({ ...data }).unwrap();

        if (result.token) {
          localStorage.setItem("token", result.token);
          localStorage.setItem("user", JSON.stringify(result.data));
          toast.success('Signup Successfull')
          dispatch(setUser(result.data));
          setTimeout(()=>{navigate("/");},1000) 
        }
      } else {
        setError("confirm", {
          type: "manual",
          message: "Passwords do not match",
        });
      }
    } catch (err) {
      if (err.data.error) {
        toast.error(err.data.error);
      } else {
        toast.error("something went wrong");
      }
    }finally{
      setLoading(false)
    }
  };

  const goBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  return (
    <>
      <div className="h-screen bg-neutral-200 flex justify-center">
        <div className="container h-full p-10">
          <div className="g-6 flex h-full flex-wrap items-center justify-center text-blue-800 ">
            <div className="w-full">
              <div className="block rounded-lg bg-white shadow-lg ">
                <div className="g-0 lg:flex lg:flex-wrap">
                  <div className="flex items-center bg-gradient-to-r from-cyan-900 from-10% via-blue-900 via-30% to-blue-950 to-90% rounded-b-lg lg:w-6/12 lg:rounded-r-lg lg:rounded-bl-none">
                    <div className="px-4 py-6 text-white md:mx-6 md:p-12">
                      <h4 className="mb-6 text-xl font-semibold">
                        The Platform to become a Pro.
                      </h4>
                      <p className="text-sm">
                        Portal Pong is the tournament software you've been
                        waiting for. We currently support USATT and Ratings
                        Central as well as custom rating systems; round robin,
                        single elimination, and custom draws; singles, doubles,
                        and Swaythling Cup or Olympic team formats;
                        international currencies (USD, CAD, GBP, EUR); live
                        scoring, self-reported results, and printable match
                        cards; and much more. Email hello@tabletennis.com today
                        to get your free personal demo.
                      </p>
                    </div>
                  </div>
                  <div className="px-4 md:px-0 lg:w-6/12">
                    <div className="md:mx-6 md:p-12">
                      <div>
                        <button onClick={goBack}>
                          <ArrowLeftIcon width={25} height={25} />
                        </button>
                      </div>
                      <div className="text-center">
                        <div className=" font-secondary text-2xl md:text-6xl">
                          <span className="text-cyan-800">Portal</span> Pong
                        </div>
                        <h4 className="mb-12 mt-3 pb-1 font-light">
                          Join us to become a part on the Team.
                        </h4>
                      </div>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <p className="mb-4">Create your account</p>
                        <div className="grid">
                          <input
                            type="email"
                            id="email"
                            minLength={6}
                            maxLength={30}
                            placeholder="Enter Email"
                            className=" py-3 dark:bg-white bg-gray-100 border-2 border-blue-800 dark:text-blue-800 rounded px-3 text-sm"
                            {...register("email", {
                              required: "Email is required",
                            })}
                          />
                          <FormFieldError errors={errors} field={"email"} />
                          <input
                            placeholder="password"
                            type="password"
                            id="password"
                            minLength={6}
                            maxLength={30}
                            className="mt-4 py-3 dark:bg-white bg-gray-100 border-2 border-blue-800 dark:text-blue-800 rounded px-3 text-sm"
                            {...register("password", {
                              required: "Password is required",
                            })}
                          />
                          <FormFieldError errors={errors} field={"password"} />
                          <input
                            placeholder="confirm password"
                            type="password"
                            id="confirm"
                            className="mt-4 py-3 dark:bg-white bg-gray-100 border-2 border-blue-800 dark:text-blue-800 rounded px-3 text-sm"
                            {...register("confirm", {
                              required: "Confirm Password is required",
                            })}
                          />
                          <FormFieldError errors={errors} field={"confirm"} />
                        </div>
                        <button
                        disabled={loading}
                          className="mt-4 mb-12  text-center bg-gradient-to-r from-cyan-700 via-blue-900 to-blue-800 inline-block w-full rounded px-6 pb-2 pt-2.5 text-xs font-medium leading-normal text-white"
                          type="submit"
                        >
                          {loading ? (
                            <div className="flex items-center justify-center">
                              <svg
                                className="animate-spin h-5 w-5 text-gray-100"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                ></path>
                              </svg>
                            </div>
                          ) : (
                            "SignUp"
                          )}
                        </button>
                      </form>
                      <FormFieldError errors={errors} field={"submitError"} />
                      <div className="flex items-center justify-between pb-6">
                        <p className="mb-0 mr-2">Already have an Account?</p>
                        <Link
                          to="/login"
                          className="inline-block rounded border-2 border-blue-800 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-danger"
                        >
                          Login
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default SignUp;
