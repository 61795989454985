import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useGetExportMatchesQuery } from "../../../redux/features/api/apiSlice";

const handleResultFile = async ({ tournamentId, selectedEvents }) => {
  const headers = ["MemNum_W", "MemNum_L", "Score", "Division"];

  const csvContent = [
    headers.join(","),
    ...selectedEvents.flatMap(
      (event) =>
        event.matches
          .filter((match) => match.result !== "")
          .map((match) => {
            const [playerA, playerB] = match.matchPlayers || [];

            let winner,
              loser,
              scoreDifferences = [];

            // Check if both players exist
            if (playerA && playerB && match.rounds?.length) {
              const resultA = match.result.A;
              const resultB = match.result.B;

              if (resultA > resultB) {
                winner = playerA.memberId || playerA.tempId;
                loser = playerB.memberId || playerB.tempId;
                match.rounds.forEach((round) => {
                  scoreDifferences.push(round.A - round.B); // Positive difference for player A's win
                });
              } else {
                winner = playerB.memberId || playerB.tempId;
                loser = playerA.memberId || playerA.tempId;
                match.rounds.forEach((round) => {
                  scoreDifferences.push(round.B - round.A); // Positive difference for player B's win
                });
              }

              // Join all score differences into a single string, formatted for a single column
              const score = `"${scoreDifferences.join(", ")}"`;
              const maxRating = event?.entryRestrictions?.maxRating;
              const groupType =
                match.group.type === "SE" ? match.group.type : "";
              const stage = match.group.stage || "";
              const division = `U/${
                maxRating || ""
              } ${groupType} ${stage}`.trim();

              return [
                winner, // MemNum_W
                loser, // MemNum_L
                score, // Score as a single column
                division,
              ].join(",");
            }

            // Return null if data is missing
            return null;
          })
          .filter(Boolean) // Filter out any null rows
    ),
  ].join("\n");

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `Result_${tournamentId}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const ExportResult = ({ handleCloseResult }) => {
  const { tournamentId } = useParams();
  const { data } = useGetExportMatchesQuery(tournamentId);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSelectAll = () => {
    const allEventIds =
      data?.data?.events?.filter(
        (event) =>
          event.matches.length > 0 &&
          event.matches.some((match) => match.result !== "")
      ) || [];
    setSelectedEvents(allEventIds);
  };

  const handleDeselectAll = () => {
    setSelectedEvents([]);
  };

  const handleToggleEvent = (event) => {
    if (selectedEvents.some((e) => e.id === event.id)) {
      setSelectedEvents(selectedEvents.filter((e) => e.id !== event.id));
    } else {
      setSelectedEvents([...selectedEvents, event]);
    }
  };

  const handleExportResults = async () => {
    if (loading) return;
    setLoading(true);
    try {
      await handleResultFile({ tournamentId, selectedEvents });
      toast.success("Results Exported");
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (e.target.classList.contains("modal-overlay")) {
        handleCloseResult(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [handleCloseResult]);

  return (
    <div
      className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50 modal-overlay"
      onClick={() => handleCloseResult(false)}
    >
      <div
        className="bg-white rounded-md shadow-lg relative"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-end p-2 bg-blue-800 rounded-t-md">
          <button
            className="px-2 rounded border-2 bg-white border-gray-600 font-medium mb-2"
            onClick={() => handleCloseResult()}
          >
            x
          </button>
        </div>
        <div className="px-10 py-3">
          {data?.data?.events?.length > 0 ? (
            <div className="mt-5">
              <div className="text-white font-medium my-3 flex justify-between gap-10">
                <button
                  className="bg-blue-600 rounded p-2"
                  onClick={handleSelectAll}
                >
                  Select All
                </button>
                <button
                  className="bg-blue-600 rounded p-2"
                  onClick={handleDeselectAll}
                >
                  Deselect All
                </button>
              </div>
              <div>
                {data?.data?.events?.map((event, i) => (
                  <div
                    className={`w-full flex mt-10 gap-3 border-b pb-3 ${
                      event.matches.length === 0 ? "opacity-30" : "opacity-100"
                    }`}
                    key={event.id}
                  >
                    <div>
                      <input
                        type="checkbox"
                        checked={selectedEvents.some((e) => e.id === event.id)}
                        onChange={() => handleToggleEvent(event)}
                        disabled={
                          event.matches.filter((match) => match.result !== "")
                            .length < 1
                        }
                      />
                    </div>
                    <div>
                      <div className="text-xl font-semibold">
                        {i + 1}. {event.eventName}
                      </div>
                      <div className="text-sm font-medium flex gap-3 text-gray-600">
                        <div>{event.matches.length} Matches</div>
                        <span>&#x2022;</span>
                        <div>
                          {
                            event.matches.filter((match) => match.result !== "")
                              .length
                          }{" "}
                          Played
                        </div>
                        <span>&#x2022;</span>
                        <div>
                          {
                            event.matches.filter((match) => match.result !== "")
                              .length
                          }{" "}
                          Exportable
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <button
                disabled={selectedEvents.length === 0}
                onClick={handleExportResults}
                className={`text-center ${
                  selectedEvents.length === 0 ? "opacity-50" : ""
                } font-medium py-2 bg-blue-800 hover:bg-blue-900 text-white w-full mt-3 rounded border shadow`}
              >
                {loading ? 'Exporting..' : 'Export Result'}
              </button>
            </div>
          ) : (
            <div className="mt-3 bg-gray-100 border shadow rounded-xl p-5">
              <div className="text-center text-gray-500">No Event Found</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExportResult;
