import React, { useEffect, useState } from "react";
import FinalisedSingleElimination from "../../components/sections/draws/FinalisedSingleElimination";
import GroupCard from "../../components/card/Group";

const DisplayScreen = ({ draw, active }) => {
  const [stage, setStage] = useState(active);

  useEffect(() => {
    setStage(active);
  }, [active]);

  return (
    <div className="w-full px-20">
      {draw?.drawFormat === "RR" && (
        <div className="w-full flex gap-3 justify-center mb-10">
          <button
            onClick={() => setStage(false)}
            className={`font-medium px-3 py-3 rounded text-white ${
              !stage ? "bg-blue-800" : "bg-gray-300"
            }`}
          >
            Group Stage
          </button>
          <button
            onClick={() => setStage(true)}
            className={`font-medium px-3 py-3 rounded text-white ${
              stage ? "bg-blue-800" : "bg-gray-300"
            }`}
          >
            Knockout Stage
          </button>
        </div>
      )}

      {!stage ? (
        <div className="w-full grid grid-cols-2 gap-10">
          {draw?.groups
            ?.filter((group) => group.type === "RR")
            .sort((a, b) => a.number - b.number)
            .map((group, i) => (
              <GroupCard
                group={group}
                roundRobbin={draw?.roundRobinSettings}
                tournament={draw?.event?.tournamentId}
                key={i}
                admin={false}
              />
            ))}
        </div>
      ) : (
        <FinalisedSingleElimination drawId={draw?.id} admin={false} />
      )}
    </div>
  );
};

export default DisplayScreen;
