import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useActivateKnockoutMutation,
  useFinalDrawMutation,
  useGetEventDrawQuery,
  useResetDrawMutation,
} from "../../../redux/features/api/apiSlice";
import loadingImg from "../../../images/loading-image.svg";
import { useSelector } from "react-redux";
import { SquaresPlusIcon } from "@heroicons/react/16/solid";
import AddDrawForm from "../../../pages/admin/Events/AddDrawForm";
import { toast } from "react-toastify";
import GroupCard from "../../card/Group";
import RoundRobin from "../draws/RoundRobin";
import SingleElimination from "../draws/SingleElimination";
import FinalisedSingleElimination from "../draws/FinalisedSingleElimination";

const DrawsTab = ({ event, tournament }) => {
  let { tournamentId, eventId } = useParams();
  const [finalDraw] = useFinalDrawMutation();
  const [activateKnockout] = useActivateKnockoutMutation();

  const [resetDraw] = useResetDrawMutation();
  const { isFetching, refetch } = useGetEventDrawQuery(eventId);
  const { draw } = useSelector((state) => state.draws);
  const [drawForm, setDrawForm] = useState(false);
  const [deleteDraw, setDelteDraw] = useState(false);
  const [loading, setLoading] = useState(false);
  const [seeding, setSeeding] = useState(true);
  const [stage, setStage] = useState(
    draw?.roundRobinSettings?.activateSE || false
  );
  const [confirmActivate, setConfirmActivate] = useState(false);
  const [confirmFinalize, setConfirmFinalize] = useState(false);

  const handleDrawForm = () => {
    setDrawForm(false);
  };

  const handleDeleteDraw = () => {
    setDelteDraw(!deleteDraw);
  };

  const finaliseDraw = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await finalDraw({
        id: draw.id,
        data: { finalised: !draw.finalised, tournamentId, eventId },
      });
      if (res?.data?.error === false) {
        toast.success(res.data.data);
        window.location.reload();
      }
      if (res?.error?.data?.error !== undefined) {
        toast.success(res.error.data.error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setConfirmFinalize()
    }
  };

  const handleActivateKnockout = async () => {
    if (loading) return;
    setLoading(true);

    try {
      let roundRobinSettings = { ...draw?.roundRobinSettings }; // Create a copy of roundRobinSettings
      roundRobinSettings.activateSE = !roundRobinSettings.activateSE; // Toggle activateSE

      const res = await activateKnockout({
        id: draw.id,
        data: { roundRobinSettings, tournamentId }, // Send updated roundRobinSettings
      });

      if (res?.data?.error === false) {
        toast.success(res.data.data);
      } else if (res?.error?.data?.error) {
        toast.error(res.error.data.error);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
    } finally {
      setLoading(false);
      setConfirmActivate(false)
    }
  };

  const handleResetDraw = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await resetDraw(draw.id);
      if (res?.data?.error === false) {
        toast.success(res.data.data);
      }
      if (res?.error?.data?.error !== undefined) {
        toast.success(res.error.data.error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refetch();
  }, [eventId, deleteDraw]);

  if (isFetching)
    return (
      <div className=" h-screen w-full flex justify-center items-center">
        <img className="-mt-32" src={loadingImg} alt="" />
      </div>
    );

  return (
    <>
      {drawForm ? (
        <AddDrawForm
          handleCloseForm={handleDrawForm}
          handleDeleteForm={handleDeleteDraw}
          event={event}
          tournament={tournament}
          draw={draw}
          finalised={draw?.finalised}
        />
      ) : confirmActivate ? (
        <div
          className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50 modal-overlay"
          onClick={() => setConfirmActivate(false)}
        >
          <div
            className="bg-white rounded-lg p-5"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="text-lg text-gray-800 text-center font-bold">
              Are you sure?
            </div>
            <div className="text-xs text-gray-500 text-center max-w-md">
              {"("}Knockout Stage is not started yet! Activate to Start, Once
              Stated cannot go back to Group Stage{")"}
            </div>
            <div className="flex justify-center mt-10 gap-10 text-white font-medium">
              <button
                className="text-sm px-5 py-2 rounded bg-gray-400 hover:bg-gray-500 border"
                onClick={() => setConfirmActivate(false)}
              >
                Cancel
              </button>
              <button
                className="text-sm px-5 rounded py-2 bg-green-600 hover:bg-green-700 border"
                onClick={handleActivateKnockout}
              >
               {loading ? 'loading..' : 'Confirm'}
              </button>
            </div>
          </div>
        </div>
      ) : 
      confirmFinalize ? (
        <div
          className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50 modal-overlay"
          onClick={() => setConfirmFinalize(false)}
        >
          <div
            className="bg-white rounded-lg p-5"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="text-lg text-gray-800 text-center font-bold">
              Are you sure?
            </div>
            <div className="text-xs text-gray-500 text-center max-w-md mt-5">
            {draw?.finalised ? "All the current Match data and Results will be lost for this draw" : "The Draw settings will be finalized and Event Matches will start."}
            </div>
            <div className="flex justify-center mt-10 gap-10 text-white font-medium">
              <button
                className="text-sm px-5 py-2 rounded bg-gray-400 hover:bg-gray-500 border"
                onClick={() => setConfirmFinalize(false)}
              >
                Cancel
              </button>
              <button
                className={`text-sm px-5 rounded py-2 ${draw?.finalised ? 'bg-red-600 hover:bg-red-700' : 'bg-green-600 hover:bg-green-700'}  border`}
                onClick={finaliseDraw}
              >
                {loading ? 'loading..' : 'Confirm'}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="flex flex-col items-start justify-start w-full mt-8">
            {draw?.id ? (
              <div className="w-full">
                <div className="flex justify-between">
                  <div className="flex items-start gap-2 mt-5 mb-10">
                    <button onClick={() => setDrawForm(true)} className="mt-1">
                      <SquaresPlusIcon height={20} width={20} />
                    </button>

                    <div className=" ">
                      <div className="flex items-center gap-3">
                        <div className="text-lg font-bold">
                          {draw?.drawName}
                        </div>
                      </div>
                      <div className="flex items-center gap-1 mt-2">
                        <div className="text-xs font-medium text-gray-600">
                          Estemated Start: {draw?.startDate?.split("T")[0]},{" "}
                        </div>
                        <div className="text-xs font-medium text-gray-600">
                          {" "}
                          {draw?.startTime}
                        </div>
                      </div>
                      <div className="flex items-center gap-1 mt-1">
                        <div className="text-xs font-medium text-gray-600">
                          Format:{" "}
                          {draw?.drawFormat === "RR"
                            ? "Round-Robin"
                            : "Single Elimination"}
                        </div>
                      </div>
                      <div className="flex items-center gap-1 mt-1">
                        <div className="text-xs font-medium text-gray-600">
                          First to {draw?.defaultMatchFormat?.pointsPerGame}{" "}
                          (Win by {draw?.defaultMatchFormat?.deuce ? "2" : "1"}{" "}
                          ) <span className="text-sm font-black">.</span> Best
                          of {draw?.defaultMatchFormat?.gamesPerMatch}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                      onClick={()=>setConfirmFinalize(true)}
                      className={` ${
                        draw?.finalised ? "bg-red-600" : "bg-blue-700"
                      } rounded shadow border text-white px-3 py-2`}
                    >
                      {draw?.finalised ? "Undo Draw" : "Finalize Draw"}
                    </button>
                  </div>
                </div>

                <div className="mb-5 flex gap-3 text-sm">
                  {!draw?.finalised && (
                    <>
                      <button
                        onClick={handleResetDraw}
                        className={` border-blue-600 rounded shadow hover:shadow-lg border text-blue-700 px-3 py-2`}
                      >
                        Reset to Default
                      </button>
                      <button
                        onClick={() => setSeeding(!seeding)}
                        className={`   border-blue-600 rounded shadow hover:shadow-lg border text-blue-700 px-3 py-2`}
                      >
                        Seeding Rules {seeding ? "On" : "Off"}
                      </button>
                    </>
                  )}
                </div>

                {draw?.drawFormat === "RR" ? (
                  <>
                    {draw?.finalised ? (
                      <div className="w-full">
                        <div className="w-full flex gap-3 justify-center mb-10">
                          <button
                            onClick={() => setStage(false)}
                            className={`font-medium px-3 py-3 rounded text-white ${
                              !stage ? "bg-blue-800" : "bg-gray-300"
                            }`}
                          >
                            Group Stage
                          </button>
                          <button
                            onClick={() => setStage(true)}
                            className={`font-medium px-3 py-3 rounded text-white ${
                              stage ? "bg-blue-800" : "bg-gray-300"
                            }`}
                          >
                            Knockout Stage
                          </button>
                        </div>
                        {!stage ? (
                          <div className="w-full grid grid-cols-2 gap-10">
                            {draw?.groups &&
                              draw?.groups?.length &&
                              draw.groups
                                .filter((group) => group.type === "RR")
                                .slice()
                                .sort((a, b) => a.number - b.number)
                                .map((group, i) => (
                                  <GroupCard
                                    group={group}
                                    roundRobbin={draw?.roundRobinSettings}
                                    key={i}
                                    admin={true}
                                  />
                                ))}
                          </div>
                        ) : (
                          <div>
                            {!draw?.roundRobinSettings?.activateSE && (
                              <div className="w-full grid grid-cols-3 gap-10 items-center mb-5">
                                <div></div>
                                <div className="text-xs text-gray-500 text-center">
                                  {"("}Knockout Stage is not started yet!
                                  Activate to Start, Once Stated cannot go back
                                  to Group Stage{")"}
                                </div>
                                <button
                                  onClick={() => setConfirmActivate(true)}
                                  className="px-2 py-3 rounded bg-green-600 hover:bg-green-700 text-sm font-medium text-white"
                                >
                                  Activate Knockout
                                </button>
                              </div>
                            )}

                            <FinalisedSingleElimination
                              drawId={draw?.id}
                              admin={true}
                            />
                          </div>
                        )}
                      </div>
                    ) : (
                      <>
                        <RoundRobin draw={draw} seeding={seeding} />
                      </>
                    )}{" "}
                  </>
                ) : (
                  <div>
                    {draw?.finalised ? (
                      <FinalisedSingleElimination
                        drawId={draw?.id}
                        admin={true}
                      />
                    ) : (
                      <>
                        <SingleElimination draw={draw} seeding={seeding} />
                      </>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className="w-full flex justify-center">
                <div className="text-center mt-20 text-gray-500">
                  No Draw Created Yet!
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default DrawsTab;
