import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  SquaresPlusIcon,
  ArchiveBoxXMarkIcon,
} from "@heroicons/react/24/solid";
import AddPlayerForm from "../../../pages/admin/Players/AddPlayerForm";
import { useSelector } from "react-redux";
import {
  useEntryCheckinMutation,
  useEventRemovePlayerMutation,
  useGetEventsByTournamentIdQuery,
  useGetTournamentByIdQuery,
} from "../../../redux/features/api/apiSlice";
import { toast } from "react-toastify";

const handleExportPlayers = async ({ entries }) => {
  const headers = [
    "firstName",
    "lastName",
    "gender",
    "birthDate",
    "phone",
    "email",
    "address",
    "city",
    "state",
    "zipCode",
    "homeClub",
  ];

  const csvContent = [
    headers.join(","), // CSV header
    ...entries.map((entry) =>
      [
        entry.player.firstName,
        entry.player.lastName,
        entry.player.sex,
        entry.player.dob,
        entry.player.phone,
        entry.player.email,
        entry.player.address1,
        entry.player.city,
        entry.player.state,
        entry.player.zip,
        entry.player.homeClub,
      ].join(",")
    ),
  ].join("\n");

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `players.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const AdminPlayersTab = ({ entries, removePlayer }) => {
  const { tournamentId, eventId } = useParams();
  useGetTournamentByIdQuery(tournamentId);
  const { tournament } = useSelector((state) => state.tournaments);
  useGetEventsByTournamentIdQuery(tournamentId);
  const { events } = useSelector((state) => state.events);
  const [removeEventPlayer] = useEventRemovePlayerMutation();
  const [entryCheckin] = useEntryCheckinMutation();
  const [playerForm, setPlayerForm] = useState(false);
  const [entry, setEntry] = useState("");
  const [isToggle, setIsToggle] = useState(false);
  const [exportPlayer, setExportPlayer] = useState(false);
  const [remove, setRemove] = useState(false);
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState("");
  const [data, setData] = useState("");

  const handleToggleChange = async (data) => {
    try {
      const res = await entryCheckin(data);
      if (!res.data.error) {
        toast.info(`Player ${data.checkin ? "checked out" : "checked in"}`);
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setIsToggle(!isToggle);
    }
  };

  const handleRemovePlayer = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await removeEventPlayer(data);
      if (!res.data.error) {
        toast.info("Player Removed From Event");
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
      setIndex("");
      setRemove(false);
      setData("");
    }
  };

  const closePlayerForm = () => {
    setPlayerForm(false);
  };

  const openPlayerForm = (data) => {
    setEntry(data);
    setPlayerForm(true);
  };

  const exportPlayers = async () => {
    if (exportPlayer) return;
    setExportPlayer(true);
    try {
      await handleExportPlayers({ entries });
      toast.success("Players Exported");
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setExportPlayer(false);
    }
  };

  return (
    <>
      {playerForm ? (
        <AddPlayerForm
          handleCloseForm={closePlayerForm}
          info={{ events, tournament, entry }}
        />
      ) : remove ? (
        <div
          className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50 modal-overlay"
          onClick={() => {
            setRemove(false);
            setIndex("");
            setData("");
          }}
        >
          <div
            className="bg-white rounded-lg p-5"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="text-lg text-gray-800 text-center font-bold">
              Are you sure?
            </div>
            <div className="text-sm text-gray-500 text-center max-w-md mt-5">
              Player will not be included in the new Draw and player transaction
              will be lost.
            </div>
            <div className="flex justify-center mt-10 gap-10 text-white font-medium">
              <button
                className="text-sm px-5 py-2 rounded bg-gray-400 hover:bg-gray-500 border"
                onClick={() => {
                  setRemove(false);
                  setIndex("");
                  setData("");
                }}
              >
                Cancel
              </button>
              <button
                className={`text-sm px-5 rounded py-2 bg-red-600 hover:bg-red-700 border`}
                onClick={() =>
                  handleRemovePlayer({
                    eventId,
                    data,
                  })
                }
              >
                {loading ? 'Removing..' : 'Remove'}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="relative overflow-x-auto ">
          <div className="my-5 flex justify-between ">
            <div className="text-sm text-gray-500">
              {entries?.length} players found
            </div>
            <div onClick={exportPlayers}>
              <button className="px-3 py-2 rounded bg-blue-700 hover:bg-blue-600 hover:shadow text-white font-medium text-sm">
                {exportPlayer ? "loading.." : "Export Players"}
              </button>
            </div>
          </div>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 shadow-md sm:rounded-lg">
            <thead className="text-xs text-gray-700 uppercase bg-gray-200">
              <tr>
                <th scope="col" className="px-3 py-3 w-[4%]">
                  Edit
                </th>
                <th scope="col" className="px-3 py-3 w-[8%]">
                  Checkin
                </th>
                <th scope="col" className="px-3 py-3 w-[23%]">
                  Player
                </th>
                <th scope="col" className="px-3 py-3 w-[10%]">
                  Member ID
                </th>
                <th scope="col" className="px-3 py-3 w-[16%]">
                  Events
                </th>
                <th scope="col" className="px-2 py-3 w-[5%]">
                  Total
                </th>
                <th scope="col" className="px-2 py-3 w-[5%]">
                  Paid
                </th>
                <th scope="col" className="px-2 py-3 w-[5%]">
                  Remaining
                </th>
                <th scope="col" className="px-3 py-3 w-[18%]">
                  Notes
                </th>
                {removePlayer && (
                  <th scope="col" className="px-3 py-3 w-[4%]">
                    Remove
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {entries?.map((entry, i) => (
                <tr
                  key={i}
                  className="odd:bg-gray-50  even:bg-gray-100 border-b"
                >
                  <td className="px-3  justify-center py-4">
                    <button
                      onClick={() => openPlayerForm(entry)}
                      className="mt-1"
                    >
                      <SquaresPlusIcon height={20} width={20} />
                    </button>
                  </td>
                  <td className="px-3  py-4">
                    <div className="text-[10px]">
                      {entry?.checkins.length} checkins
                    </div>

                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        checked={entry?.checkin}
                        onChange={() => handleToggleChange(entry)}
                        className="sr-only peer"
                      />
                      <div className="relative w-10 h-4 bg-gray-300 rounded-full peer  peer-checked:after:translate-x-[22px] rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[0px] after:start-[1px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                    <div className="text-[8px] leading-3">
                      {entry?.checkins[0]?.date}
                    </div>
                    <div className="text-[8px] leading-3">
                      {entry?.checkins[0]?.time}
                    </div>
                  </td>
                  <th
                    scope="row"
                    className="px-1 py-1 font-medium text-gray-900 whitespace-nowrap "
                  >
                    <div className="bg-white rounded-xl px-3">
                      <div className="flex gap-5">
                        <div className="text-[10px] text-gray-500">
                          RATING: {entry?.player?.rating}
                        </div>
                        <div className="text-[10px] text-gray-500 font-medium">
                          {entry?.player?.sex}
                        </div>
                      </div>

                      <div className="text-lg">
                        {entry?.player?.firstName}, {entry?.player?.lastName}
                      </div>
                    </div>
                  </th>
                  <td className="px-2 py-4">
                    <div
                      className={`text-xs px-1 ${
                        entry?.player?.memberId
                          ? "bg-blue-500 text-white"
                          : "bg-yellow-200"
                      } text-center`}
                    >
                      {entry?.player?.memberId
                        ? entry?.player?.memberId
                        : entry?.player?.tempId}
                    </div>
                  </td>
                  <td className="px-3 py-1">
                    {" "}
                    <div className="flex flex-wrap gap-1">
                      {entry?.events?.map((event, i) => (
                        <div
                          key={i}
                          className="text-xs bg-gray-300 rounded-sm px-3"
                        >
                          {event?.eventName}
                        </div>
                      ))}
                    </div>
                  </td>
                  <td className="px-2 py-4">
                    <div className="font-medium">
                      $
                      {(
                        (Number(entry?.tournamentFee) || 0) +
                        entry?.events?.reduce(
                          (sum, event) => sum + (Number(event?.eventFee) || 0),
                          0
                        ) +
                        entry?.charges?.reduce(
                          (sum, charge) => sum + (Number(charge?.charge) || 0),
                          0
                        ) +
                        (Number(entry?.membershipFee) || 0)
                      ).toFixed(2)}
                    </div>
                  </td>
                  <td className="px-2 py-4">
                    <div className="font-medium">
                      $
                      {entry?.payments?.reduce(
                        (sum, payment) => sum + (Number(payment?.payment) || 0),
                        0
                      ) +
                        entry?.discounts?.reduce(
                          (sum, discount) =>
                            sum + (Number(discount?.discount) || 0),
                          0
                        )}
                    </div>
                  </td>
                  <td className="px-2 py-4">
                    <div className="font-medium text-red-600">
                      $
                      {(
                        (Number(entry?.tournamentFee) || 0) +
                        entry?.events?.reduce(
                          (sum, event) => sum + (Number(event?.eventFee) || 0),
                          0
                        ) +
                        entry?.charges?.reduce(
                          (sum, charge) => sum + (Number(charge?.charge) || 0),
                          0
                        ) +
                        (Number(entry?.membershipFee) || 0) -
                        entry?.payments?.reduce(
                          (sum, payment) =>
                            sum + (Number(payment?.payment) || 0),
                          0
                        ) -
                        entry?.discounts?.reduce(
                          (sum, discount) =>
                            sum + (Number(discount?.discount) || 0),
                          0
                        )
                      ).toFixed(2)}
                    </div>
                  </td>
                  <td className="px-3 py-1 text-xs">
                    {entry?.notes?.selfRegistered && (
                      <div className="bg-gray-300 text-center self-center text-white p-0.5 rounded-sm px-1">
                        SELF-REGISTERED
                      </div>
                    )}
                    {entry?.notes?.membershipSold && (
                      <div className="bg-blue-300 text-center self-center text-white p-0.5 rounded-sm px-1">
                        MEMBERSHIP SOLD
                      </div>
                    )}
                    {entry?.notes?.membershipExpired && (
                      <div className="bg-gray-400 text-center self-center text-white p-0.5 rounded-sm px-1">
                        MEMBERSHIP EXPIRED
                      </div>
                    )}
                  </td>
                  {removePlayer && (
                    <td className="px-3 py-1 text-xs">
                      <button
                        disabled={loading}
                        onClick={() => {
                          setData({
                            eventId,
                            data: {
                              playerId: entry.player.id,
                              entryId: entry.id,
                            },
                          });
                          setRemove(true);
                          // handleRemovePlayer({
                          //   eventId,
                          //   data: {
                          //     playerId: entry.player.id,
                          //     entryId: entry.id,
                          //   },
                          // });
                          setIndex(i);
                        }}
                        className="hover:scale-110"
                        title="Remove Player"
                      >
                        {loading && index === i ? (
                          <div className="flex items-center justify-center">
                            <svg
                              className="animate-spin h-5 w-5 text-gray-100"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                              ></path>
                            </svg>
                          </div>
                        ) : (
                          <ArchiveBoxXMarkIcon height={20} width={20} />
                        )}
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default AdminPlayersTab;
