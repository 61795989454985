import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  UserIcon,
} from "@heroicons/react/24/solid";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../redux/features/userSlice";
import { LockClosedIcon } from "@heroicons/react/16/solid";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Header = () => {
  const { currentUser } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logoutUser());
    toast.info('Logged Out!')
    navigate("/");
  };

  return (
    <>
    <div className="py-4 bg-white px-3">
      <div className="container flex flex-row items-center justify-between mx-auto drop-shadow-sm">
        <h1 className="text-xl sm:text-2xl font-bold uppercase"><span className="text-cyan-800">portal</span><span className="text-blue-800">pong</span></h1>
        <div className="text-blue-900">
          <ul className="flex flex-row items-center justify-between gap-2 sm:gap-4">
            {currentUser.id && (
              <>
              <li className="flex flex-row items-center justify-between gap-2">
              <LockClosedIcon height={16} width={16} />
                <Link to="/admin" className="text-xs sm:text-lg font-medium">
                  Admin
                </Link>
              </li>
              <li className="flex flex-row items-center justify-between gap-2">
              <UserIcon height={16} width={16} />
                <Link to="/profile" className="text-xs sm:text-lg font-medium">
                  Profile
                </Link>
              </li>
              </>
            )
            }

            <li className="flex flex-row items-center justify-between gap-2">
              
              {currentUser.id ? (
                <button onClick={handleLogout} className="text-xs sm:text-lg font-medium px-3 border-2 border-blue-900 rounded-full">
                  Logout
                </button>
              ) : (
                <Link to={"/login"} className="text-xs sm:text-lg font-medium px-5 border-2 py-1 shadow rounded-full bg-blue-900 text-white">
                  Login
                </Link>
              )}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <ToastContainer />
    </>
  );
};

export default Header;
