import React from "react";
import { useParams } from "react-router-dom";
import {
  useGetTournamentFinancesQuery,
} from "../../../redux/features/api/apiSlice";
import { useSelector } from "react-redux";
import loadingImg from "../../../images/loading-image.svg";


const TournamentFinanceTab = () => {
  let { tournamentId } = useParams();
  const { isFetching } = useGetTournamentFinancesQuery(tournamentId);
  const { finances } = useSelector((state) => state.entries);
  let amount = finances.totals

  if (isFetching) return (
    <div className=" h-screen w-full flex justify-center items-center">
      <img className="-mt-32" src={loadingImg} alt="" />
    </div>
  );

  return (
    <>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-200">
            <tr>
              <th scope="col" className="px-6 py-3 w-[18%]">
              </th>
              <th scope="col" className="px-6 py-3 w-[12%]">
                Player Fees
              </th>
              <th scope="col" className="px-6 py-3 w-[15%]">
                Custom Charges
              </th>
              <th scope="col" className="px-6 py-3 w-[15%]">
                Custiom Discounts
              </th>
              <th scope="col" className="px-6 py-3 w-[10%]">
                Total Due
              </th>
              <th scope="col" className="px-6 py-3 w-[10%]">
                Total Paid
              </th>
              <th scope="col" className="px-6 py-3 w-[15%]">
                Remaining Balance
              </th>
            </tr>
          </thead>
          <tbody>
              <tr className="odd:bg-white  even:bg-gray-50 border-b">
                <td className="px-6 py-4">
                  <div>All Registered Players</div>
                </td>
                <td
                  className="px-6 py-1 font-medium text-gray-900 whitespace-nowrap"
                >
                 ${amount?.total || 0}
                </td>
                <td className="px-6 py-4">
                ${amount?.charge || 0}
                </td>
                <td className="px-6 py-4">
                ${amount?.discount || 0}
                </td>
                <td className="px-6 py-4 text-black font-medium">
                ${amount?.total + amount?.charge - amount?.discount || 0}
                </td>
                <td className="px-6 py-4 text-black font-medium">
                ${amount?.amountPaid || 0}
                </td>
                <td className="px-6 py-4 text-black font-medium">
                ${amount?.remaining || 0}
                </td>
              </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TournamentFinanceTab;
