import React, { useState } from "react";
import { toast } from "react-toastify";
import { useDrawSeedingMutation } from "../../../redux/features/api/apiSlice";

const SingleElimination = ({ draw, seeding }) => {
  const [drawSeeding] = useDrawSeedingMutation();
  const [player1, setPlayer1] = useState("");
  const [hoveredPlayer, setHoveredPlayer] = useState(null);
  const [pair, setPair] = useState(null);

  const handleSeeding = async (data, i) => {
    try {
      if (pair === i || pair === null) {
        setPair(i);
        setPlayer1(data);
        return;
      }
      if (player1 === "") {
        setPlayer1(data);
      } else {
        const res = await drawSeeding({
          id: draw.id,
          data: { player1, data, seeding, format: "SE" },
        });
        if (res?.data?.error === false) {
          toast.success(res.data.data);
          setPlayer1("");
        }
        if (res?.error?.data?.error !== undefined) {
          toast.success(res.error.data.error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log(player1)
  return (
    <>
      <div className={`grid grid-cols-${draw?.groups?.length} items-center`}>
        {(() => {
          if (!draw?.groups || draw.groups.length === 0) {
            return <div>No groups available</div>;
          }

          const renderRound = (group, groupIndex) => {
            // Calculate the number of players in this group based on groupIndex
            const totalPlayersInGroup = Math.pow(
              2,
              draw.groups.length - groupIndex
            );

            const sortedPlayers = group.groupPlayers
              ? [...group.groupPlayers]
                  .filter(
                    (player) =>
                      player.order >= 0 && player.order < totalPlayersInGroup
                  )
                  .sort((a, b) => a?.order - b?.order)
              : [];

            let displayPlayers;
            if (groupIndex === 0) {
              displayPlayers = sortedPlayers.slice(0, totalPlayersInGroup);
            } else {
              displayPlayers = Array.from(
                { length: totalPlayersInGroup },
                () => ({
                  player: null,
                })
              );

              sortedPlayers.forEach((player) => {
                if (player.order < displayPlayers.length) {
                  displayPlayers[player.order] = player;
                }
              });
            }

            const pairedPlayers = [];
            for (let i = 0; i < displayPlayers.length; i += 2) {
              pairedPlayers.push([
                displayPlayers[i],
                displayPlayers[i + 1] || { player: null }, // Add null if no second player in the pair
              ]);
            }

            return (
              <div
                key={groupIndex}
                className={`flex flex-col space-y-4 h-[100%]`}
              >
                {pairedPlayers.map((pair, i) => (
                  <div
                    key={i}
                    className="flex flex-col items-center justify-center space-y-1 flex-1"
                  >
                    {pair.map((entry, index) => (
                      <div
                        key={index}
                        className={`bg-gray-200 flex items-center p-1 rounded-lg  ${
                          (groupIndex === 0 && hoveredPlayer === entry.id && entry.player !== null) ||
                          player1 === entry
                            ? "cursor-pointer bg-green-300"
                            : ""
                        }`}
                        onMouseEnter={() =>
                          groupIndex === 0 && setHoveredPlayer(entry.id)
                        }
                        onMouseLeave={() =>
                          groupIndex === 0 && setHoveredPlayer("")
                        }
                        onClick={() =>
                          groupIndex === 0 && entry.player!==null && handleSeeding(entry, i)
                        }
                      
                      >
                        <div className="text-[10px] h-5 w-5 flex justify-center items-center bg-blue-300 rounded-full mx-2">
                          {parseInt(entry.number + 1) || ""}
                        </div>
                        <div
                          key={index}
                          className={`border shadow rounded-md px-3 py-2 w-44 min-h-14 ${
                            entry.player ? "bg-white" : "bg-gray-100"
                          }`}
                        >
                          {entry.player && (
                            <div className="text-[10px] text-gray-500 flex gap-3">
                              <div className="">
                                {entry.player?.rating || ""},
                              </div>
                              <div className="">
                                {entry.player?.homeAddress || ""}
                              </div>
                            </div>
                          )}

                          <div className="text-sm font-medium text-gray-700">
                            {entry.player
                              ? `${entry.player.firstName}, ${entry.player.lastName}`
                              : "TBD"}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            );
          };

          return draw.groups
            .slice()
            .sort((a, b) => a.number - b.number)
            .map((group, groupIndex) => (
              <div key={groupIndex} className="h-[100%]">
                {renderRound(group, groupIndex)}
              </div>
            ));
        })()}
      </div>
    </>
  );
};

export default SingleElimination;
