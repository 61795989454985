import React, { useEffect, useState } from "react";
import { classNames } from "../../utils/classNames";
import { ChevronDownIcon, SquaresPlusIcon } from "@heroicons/react/16/solid";
import { useParams } from "react-router-dom";
import {
  useGetGroupMatchesQuery,
  useGetTournamentTablesQuery,
  useGroupTablesMutation,
} from "../../redux/features/api/apiSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Matches from "../commons/Matches";

const Stading = ({ players, groupId, roundRobbin }) => {
  const qualify = roundRobbin?.noAdvance;

  const sortedPlayers = players
    ?.map((player) => {
      const playerStats = player.player.playerMatches.reduce(
        (stats, match) => {
          const number = match.PlayerMatch.number;
          const isPlayerA = number === 0;

          // Determine match result and round difference
          const playerAScore = match.result.A;
          const playerBScore = match.result.B;
          const wonMatch = isPlayerA
            ? playerAScore > playerBScore
            : playerBScore > playerAScore;
          const points = wonMatch ? 3 : 0;

          // Calculate round difference for Player A and Player B
          const roundDifference = isPlayerA
            ? playerAScore - playerBScore
            : playerBScore - playerAScore;

          if (match.groupId === groupId) {
            stats.totalMatches += 1;
            if (match?.result !== "") {
              stats.totalPoints += points;
              stats.roundDifference += roundDifference; // Add round difference to stats
              if (wonMatch) {
                stats.wins += 1;
              } else {
                stats.losses += 1;
              }
            }
          }

          return stats;
        },
        {
          totalPoints: 0,
          totalMatches: 0,
          wins: 0,
          losses: 0,
          roundDifference: 0, // Initialize round difference
        }
      );

      return {
        ...player,
        playerStats,
      };
    })
    .sort((a, b) => {
      if (b.playerStats.totalPoints !== a.playerStats.totalPoints) {
        return b.playerStats.totalPoints - a.playerStats.totalPoints; // Sort by points
      }
      if (b.playerStats.roundDifference !== a.playerStats.roundDifference) {
        return b.playerStats.roundDifference - a.playerStats.roundDifference; // Sort by round difference
      }
      if (b.playerStats.totalMatches !== a.playerStats.totalMatches) {
        return b.playerStats.totalMatches - a.playerStats.totalMatches; // Sort by total matches
      }
      return b.player.rating - a.player.rating; // Sort by rating
    });

  return (
    <div className="px-4 py-2 space-y-4 overflow-y-scroll divide-y max-h-[350px] border mx-3 mb-3 rounded-lg">
      {sortedPlayers?.map((player, i) => (
        <div className="flex flex-row items-center justify-start gap-4" key={i}>
          <div
            className={`w-6 h-6 text-center text-white ${
              i < qualify
                ? "bg-green-500" // First `qualify` number of players get green background
                : "bg-gray-300" // The rest get gray background
            }`}
          >
            {i + 1}
          </div>
          <div className="flex flex-col flex-1 ">
            <div>
              <span className="text-sm font-bold">{player.player.rating}</span>
              <span>-</span>
              <span className="text-sm">{player.player.homeAddress}</span>
            </div>
            <span>
              <span className="text-sm font-bold">
                {player.player.firstName},{player?.player?.lastName}
              </span>
            </span>
          </div>
          <div className="py-2 ">
            <div className="grid items-center justify-center grid-cols-5 grid-rows-2 gap-4 text-center">
              <span className="flex flex-col items-center justify-center text-xs text-center">
                M
              </span>
              <span className="flex flex-col items-center justify-center text-xs text-center">
                W
              </span>
              <span className="flex flex-col items-center justify-center text-xs text-center">
                L
              </span>
              <span className="flex flex-col items-center justify-center text-xs text-center">
                RD
              </span>
              <span className="flex flex-col items-center justify-center text-xs text-center">
                Pts
              </span>
              <span className="flex flex-col items-center justify-center text-xs text-center">
                {player.playerStats?.wins + player?.playerStats?.losses || 0}
              </span>
              <span className="flex flex-col items-center justify-center text-xs text-center">
                {player.playerStats?.wins || 0}
              </span>
              <span className="flex flex-col items-center justify-center text-xs text-center">
                {player.playerStats?.losses || 0}
              </span>
              <span className="flex flex-col items-center justify-center text-xs text-center">
                {player.playerStats?.roundDifference || 0}
              </span>
              <span className="flex flex-col items-center justify-center text-xs text-center">
                {player.playerStats?.totalPoints || 0}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const GroupCard = ({ group, tournament, roundRobbin, admin }) => {
  let { tournamentId, eventId } = useParams();
  const [groupTables] = useGroupTablesMutation();
  useGetTournamentTablesQuery(tournamentId || tournament);
  const {
    data: matches,
    refetch
  } = useGetGroupMatchesQuery(group.id);
  const { tables } = useSelector((state) => state.tables) || [];
  const [editTable, setEditTable] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTable, setSelectedTable] = useState(group?.groupTables);

  const handleTables = (table) => {
    setSelectedTable((prevSelected) => {
      if (prevSelected.some((t) => t.index === table.index)) {
        return prevSelected.filter((t) => t.index !== table.index);
      } else {
        return [...prevSelected, table];
      }
    });
  };

  const handleGroupTables = async (id) => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await groupTables({ id, data: selectedTable });
      console.log(res);
      if (res?.data?.error === false) {
        toast.success(res.data.data);
        setEditTable(false);
      }
      if (res?.error?.data?.error !== undefined) {
        toast.success(res.error.data.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const tabs = [
    {
      name: "stadings",
      href: "#",
      element: () => (
        <Stading players={group?.groupPlayers} groupId={group.id} roundRobbin={roundRobbin} />
      ),
    },
    {
      name: "matches",
      href: "#",
      element: () => (
        <Matches matches={matches?.data} admin={admin} />
      ),
    },
  ];

  const [activeTab, setActiveTab] = useState("stadings"); // stadings | matches
  const handleActiveTabChange = () => {
    setActiveTab(activeTab === "stadings" ? "matches" : "stadings");
  };

  useEffect(() => {
    refetch();
  }, [eventId]);

  return (
    <div className="flex flex-col items-start justify-start overflow-hidden bg-white rounded-md border shadow">
      <div className="flex flex-row items-center justify-between w-full px-4 py-5 text-white bg-blue-900">
        <h2 className="font-bold ">Group {group?.number + 1}</h2>
        <div className="flex items-center gap-3">
          {!editTable ? (
            <div className="flex items-center gap-3">
              {admin && (
                <button onClick={() => setEditTable(true)}>
                  <SquaresPlusIcon height={20} width={20} />
                </button>
              )}

              <div className="px-3 py-2 rounded bg-white text-black text-sm font-medium">
                {group?.groupTables?.length < 1 ? (
                  "No Table"
                ) : (
                  <>
                    Table{" "}
                    {group.groupTables
                      .slice() // Create a shallow copy of the array
                      .sort((a, b) => a.index - b.index) // Sort the copied array
                      .map((table, i) => (
                        <span key={i}>
                          {table.index}
                          {group?.groupTables?.length !== i + 1 && ", "}
                        </span>
                      ))}
                  </>
                )}
              </div>
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <button
                className="text-lg font-medium bg-white rounded h-6 w-6 text-black"
                onClick={() => setEditTable(false)}
              >
                X
              </button>
              <button
                onClick={() => setShowTable(!showTable)}
                className="flex gap-1 items-center py-2 bg-white text-black rounded px-2 text-sm"
              >
                {selectedTable?.length < 1
                  ? "No Table"
                  : selectedTable
                      .slice() // Create a shallow copy of the array
                      .sort((a, b) => a.index - b.index) // Sort the copied array
                      .map((table, i) => (
                        <span key={i}>
                          {table.index} {selectedTable.length !== i + 1 && ","}
                        </span>
                      ))}
                <ChevronDownIcon height={14} width={14} />
              </button>
              {showTable && tables.length > 0 && (
                <div className="absolute z-10 mt-[290px] space-y-2 border shadow bg-white max-h-[250px] overflow-y-scroll rounded-lg p-3">
                  {selectedTable?.length > 0 && (
                    <button
                      onClick={() => setSelectedTable([])}
                      className="text-black text-sm text-center w-full hover:bg-gray-100 py-1"
                    >
                      Reset
                    </button>
                  )}
                  {tables?.map((table, i) => {
                    const isSelected = selectedTable.some(
                      (t) => t.id === table.id
                    );
                    return (
                      <div key={i} className="text-black text-sm">
                        <button
                          onClick={() => handleTables(table)}
                          className={`flex gap-2 px-1 py-1 ${
                            isSelected ? "bg-gray-200" : "hover:bg-gray-200"
                          }`}
                        >
                          Table {table.index}{" "}
                          <span className="bg-blue-300 px-2">
                            {table?.tableNumber}
                          </span>
                        </button>
                      </div>
                    );
                  })}
                </div>
              )}
              <button
                onClick={() => handleGroupTables(group.id)}
                className="bg-blue-500 px-3 py-2 text-sm rounded shadow"
              >
                Apply
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="mt-2 ">
        <div className="">
          <nav className="flex ">
            {tabs.map((tab, i) => (
              <button
                onClick={handleActiveTabChange}
                key={i}
                className={classNames(
                  activeTab === tab.name
                    ? "text-gray-950 font-bold"
                    : "text-gray-500 font-normal",
                  "px-4 py-2 whitespace-nowrap  pb-4 text-sm font-medium capitalize"
                )}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
      <div className="w-full">
        {tabs.map((tab, i) => {
          if (tab.name === activeTab) {
            return <div key={i}>{tab.element()}</div>;
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default GroupCard;
