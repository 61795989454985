import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";

export const initialState = {
  currentUser: JSON.parse(localStorage.getItem("user")) || {},
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.currentUser = action.payload;
      localStorage.setItem("user", JSON.stringify(action.payload));  // Update localStorage
    },
    logoutUser: (state) => {
      state.currentUser = {};
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("pin");
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.getUser.matchFulfilled,
      (state, action) => {
        state.currentUser = action.payload.data[0];
        localStorage.setItem("user", JSON.stringify(action.payload.data[0]));  // Update localStorage
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.createProfile.matchFulfilled,
      (state, action) => {
        state.currentUser = action.payload.data;
        localStorage.setItem("user", JSON.stringify(action.payload.data));  // Update localStorage
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.updateProfile.matchFulfilled,
      (state, action) => {
        state.currentUser = action.payload.data;
        localStorage.setItem("user", JSON.stringify(action.payload.data));  // Update localStorage
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.connectProfile.matchFulfilled,
      (state, action) => {
        state.currentUser = action.payload.data;
        localStorage.setItem("user", JSON.stringify(action.payload.data));  // Update localStorage
      }
    );
  },
});

export const { setUser, logoutUser } = userSlice.actions;
export default userSlice.reducer;
