import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";


export const initialState = {
  group:{},
  groups: [],
};

export const groupSlice = createSlice({
  name: "group",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.getDrawGroups.matchFulfilled,
      (state, action) => {
        state.groups = action.payload.data;
      }
    );
  },
});

export default groupSlice.reducer;
