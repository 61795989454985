import React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter } from "react-router-dom";
import AdminLayout from "../layouts/layout";
import NotFound from "../pages/common/NotFound";
import * as Page from "../pages/admin";
import * as UserPage from "../pages/user";
import Login from "../pages/common/Login";
import SignUp from "../pages/common/SignUp";
import PrivateRoute from "./privateRoute";
import PublicRoute from "./publicRoute"; // Import the new PublicRoute component
import Layout from "../layouts/layout";
import Display from "../pages/common/Display";
import DisplayScreen from "../pages/common/DisplayScreen";
import MatchReporter from "../pages/common/MatchReporter";

export const router = createBrowserRouter([
  {
    path: "/login",
    element: <PublicRoute />, // Protect the login route
    children: [
      {
        path: "",
        element: <Login />,
      },
    ],
  },
  {
    path: "/signup",
    element: <PublicRoute />, // Protect the signup route
    children: [
      {
        path: "",
        element: <SignUp />,
      },
    ],
  },
  {
    path: "/display",
    element: <Display />,
  },
  {
    path: "/displayscreen",
    element: <DisplayScreen />,
  },
  {
    path: "/matchreporter",
    element: <MatchReporter />,
  },
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <UserPage.Tournaments />,
      },
      { path: "/:tournamentId", element: <UserPage.Tournament /> },
      { path: "events", element: <UserPage.Events /> },
      {
        path: "/:tournamentId/:eventId",
        element: <UserPage.Event />,
      },
      { path: "players", element: <UserPage.Players /> },
      { path: "teams", element: <UserPage.Teams /> },
      { path: "matches", element: <div>Matches</div> },
      { path: "tables", element: <div>Tables</div> },
      { path: "*", element: <NotFound /> },
    ],
  },
  {
    path: "/profile",
    element: <PrivateRoute />, // Protect admin routes
    children: [
      {
        path: "",
        element: <AdminLayout />,
        children: [
          {
            index:true,
            element: <UserPage.Profile />,
          },
          { path: "*", element: <NotFound /> },
        ],
      },
    ],
  },
  {
    path: "/admin",
    element: <PrivateRoute />, // Protect admin routes
    children: [
      {
        path: "",
        element: <AdminLayout />,
        children: [
          {
            index:true,
            element: <Page.Tournaments />,
          },
          { path: ":tournamentId", element: <Page.Tournament /> },
          { path: "events", element: <Page.Events /> },
          {
            path: ":tournamentId/:eventId",
            element: <Page.Event />,
          },
          { path: "events/:eventId", element: <Page.Event /> },
          { path: "players", element: <Page.Players /> },
          { path: "teams", element: <Page.Teams /> },
          { path: "matches", element: <div>Matches</div> },
          { path: "tables", element: <div>Tables</div> },
          { path: "*", element: <NotFound /> },
        ],
      },
    ],
  },
]);
